<template>
  <app-modal-withdraw
    :show="showModalWithdraw"
    @close="showModalWithdraw = false"
    @refresh="refresh"
  />
  <div>
    <div class="px-4 border-b p-2">
      <div
        class="
          px-5
          py-2
          flex
          m-auto
          lg:px-0
          container
          items-center
          justify-between
        "
      >
        <div>
          <p class="font-medium">Payout</p>
        </div>
        <app-button-outline
          class="flex flex-row space-x-2"
          :disabled="business.config?.payout_type == 'daily'" 
          @click="onClickWithdraw"
          >
          <app-icon name="LibraryIcon" class="h-4 w-4" />
          <p>{{ $t("wallet.payout.withdraw") }}</p>
          </app-button-outline>
        </div>
      </div>
    </div>
    
  <app-modal-payout-detail
    :show="showModalDetail"
    @close="showModalDetail = false"
    :payout="selectPayout"
    processedPaymentLinkRoute="balance.transaction.payment-link"
    @update:payout="selectPayout = $event"
  />
  <app-table
    :tabs="tabs"
    :loading="apiPaginationLoading"
    :apiResponse="apiPaginationResponse?.data ?? []"
    @params-changed="paramsChanged"
    :showEmptyButton="false"
    :filters="
      this.isMasterBusinessRole
        ? [
            {
              key: 'filter[business_id]',
              type: 'select',
              placeholder: $t('business.table.header_businss_name'),
              items: all_business,
            },
            {
              key: 'filter[_id]',
              type: 'text',
              placeholder: $t('wallet.payout.id'),
              value: '',
            },
            {
              key: 'filter[amount]',
              type: 'text',
              placeholder: $t('wallet.payout.amount'),
              value: '',
            },
            {
              key: 'filter[created_at_between]',
              type: 'date',
              placeholder: $t('wallet.payout.date'),
              value: '',
            },
          ]
        : [
            {
              key: 'filter[_id]',
              type: 'text',
              placeholder: $t('wallet.payout.id'),
              value: '',
            },
            {
              key: 'filter[amount]',
              type: 'text',
              placeholder: $t('wallet.payout.amount'),
              value: '',
            },
            {
              key: 'filter[created_at_between]',
              type: 'date',
              placeholder: $t('wallet.payout.date'),
              value: '',
            },
          ]
    "
    @on-item-click="onItemClick"
  >
    <template v-slot:header>
      <td v-if="this.isMasterBusinessRole">
        <b>{{ $t("wallet.payout.business_name") }}</b>
      </td>
      <td>
        <b>{{ $t("wallet.payout.bank_name") }}</b>
      </td>
      <td>
        <b>{{ $t("wallet.payout.account_no") }}</b>
      </td>
      <td>
        <b>{{ $t("wallet.payout.date") }}</b>
      </td>
      <td>
        <b>{{ $t("wallet.payout.amount") }}</b>
      </td>
      <td>
        <b>{{ $t("wallet.payout.status") }}</b>
      </td>
    </template>

    <template v-slot:body="data">
      <td v-if="this.isMasterBusinessRole">
        {{ data.model.business?.name }}
      </td>
      <td>
        {{ data.model.business.bank?.bank.display_name }}
      </td>
      <td>
        {{ data.model.business.bank?.account_no }}
      </td>
      <td>
        {{ $moment(data.model.created_at).format("DD MMM YYYY hh:mm a") }}
      </td>
      <td>
        {{ $formats.currency(data.model.currency, data.model.amount ?? 0.0) }}
      </td>
      <td>
        <app-badge
          class="capitalize w-24"
          :status="getStatus(data.model?.status)"
        >
          {{ data.model?.status ?? "-" }}
        </app-badge>
      </td>
    </template>
  </app-table>
</template>

<script>
import BUSINESS_STATUS from "@/utils/const/business_status";
import PAYOUT_STATUS from "@/utils/const/payout_status";

export default {
  data() {
    return {
      tabs: [],
      current_tab: null,
      filters: {},
      showModalDetail: false,
      showModalWithdraw: false,
      selectPayout: null,
      selectWithdraw: null
    };
  },
  computed: {
    apiPaginationResponse() {
      return this.$store.getters["walletStore/apiPaginationResponse"];
    },

    business() {
      return this.$store.getters["businessStore/business"];
    },

    apiPaginationLoading() {
      return this.$store.getters["walletStore/apiPaginationLoading"];
    },

    isMasterBusinessRole() {
      return this.$store.getters["authStore/isMasterBusinessRole"]
    },

    all_business() {
      return this.$store.getters["masterBusinessStore/all_business"];
    },
  },

  mounted() {
    this.fetchListPayout();

    this.tabs = [
      { key: "requested", title: this.$t("payout.table.merchant.tab_request") },
      {
        key: "processing",
        title: this.$t("payout.table.merchant.tab_settlement"),
      },
      { key: "approved", title: this.$t("payout.table.merchant.tab_approved") },
      { key: "rejected", title: this.$t("payout.table.merchant.tab_failed") },
    ];

    this.$store.dispatch("masterBusinessStore/getMasterBusiness", {
      business_status_id: BUSINESS_STATUS.APPROVED,
    });
  },
  methods: {
    getStatus(status) {
      switch (status) {
        case PAYOUT_STATUS.APPROVED:
          return "success";
        case PAYOUT_STATUS.PROCESSING:
          return "light";
        case PAYOUT_STATUS.REQUESTED:
          return "light";
        case PAYOUT_STATUS.REJECTED:
          return "error";
      }
    },

    paramsChanged(filters, queryParams) {
      this.filters = filters;
      if (this.isMasterBusinessRole) {
        this.filters["business_id"] = filters["filter[business_id]"]?.id;
        this.filters["filter[business_id]"] = this.filters["business_id"];
      }
      this.fetchListPayout();
    },

    fetchListPayout() {
      this.getTabStatusTab();
      this.$store.dispatch("walletStore/retrieveWalletPayouts", this.filters);
    },

    refresh() {
      window.location.href = this.$route.path;
    },

    getTabStatusTab() {
      var status_tab = null;
      let currentTab = this.current_tab ?? this.$route.query.tab;

      if (currentTab == PAYOUT_STATUS.APPROVED) {
        status_tab = PAYOUT_STATUS.APPROVED;
      } else if (currentTab == PAYOUT_STATUS.PROCESSING) {
        status_tab = PAYOUT_STATUS.PROCESSING;
      } else if (currentTab == PAYOUT_STATUS.REQUESTED) {
        status_tab = PAYOUT_STATUS.REQUESTED;
      } else if (currentTab == PAYOUT_STATUS.REJECTED) {
        status_tab = PAYOUT_STATUS.REJECTED;
      } else {
        status_tab = PAYOUT_STATUS.REQUESTED;
      }

      this.filters["filter[status]"] = status_tab;
    },

    onItemClick(data) {
      this.showModalDetail = !this.showModalDetail;
      this.selectPayout = data;
    },
    onClickWithdraw() {
      this.showModalWithdraw = !this.showModalWithdraw;
    },
  },
};
</script>
